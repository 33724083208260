import { logo_name } from './constants';

export class GenNums {
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
        }
    
    genereateNums(max){
        let a = [];
        for (let i=0; i < logo_name.length; i++) {
            a.push(this.getRandomInt(1, max[i]))
        };
        return a;
    }
  }