import React, { useContext, useEffect} from 'react';
import { Context } from "../index";

export const Soc = () => {
    const [context, setContext] = useContext(Context);
    function editColor(context){
        const back = document.querySelector('.backgroundApp');
        const mTop = document.querySelector('.Marquee_top');
        const mBottom = document.querySelector('.Marquee_bottom');
        if(context.git){
            back.style.backgroundColor = "#000";
            mTop.style.visibility = "visible";
            mBottom.style.visibility = "visible";
        }
        else if(context.vk){
            document.querySelector('.backgroundApp').style.backgroundColor = "#0077FF";  
            document.querySelector('.Marquee_top').style.visibility = "visible";
            document.querySelector('.Marquee_bottom').style.visibility = "visible";
        }
        else if(context.tg){
            document.querySelector('.backgroundApp').style.backgroundColor = "#039be5";  
            document.querySelector('.Marquee_top').style.visibility = "visible";
            document.querySelector('.Marquee_bottom').style.visibility = "visible";
        }
        else{
            document.querySelector('.backgroundApp').style.backgroundColor = "#222"; 
            document.querySelector('.Marquee_top').style.visibility = "hidden";
            document.querySelector('.Marquee_bottom').style.visibility = "hidden";
        }
    }
    useEffect(() => {
        editColor(context.soc);
      });
    return (
        <div className="Soc">
            <a href="/redirects/vk" target="_blank" onMouseOver={() => setContext({...context, soc: { vk: true, tg: false, git: false }})} onMouseOut={() => setContext({...context, soc: { vk: false, tg: false, git: false }})} className="vk" >
            </a>
            <a href="/redirects/git" target="_blank" onMouseOver={() => setContext({...context, soc: { vk: false, tg: false, git: true }})} onMouseOut={() => setContext({...context, soc: { vk: false, tg: false, git: false }})} className="git" >
            </a>
            <a href="/redirects/telegram" target="_blank" onMouseOver={() => setContext({...context, soc: { vk: false, tg: true, git: false }})} onMouseOut={() => setContext({...context, soc: { vk: false, tg: false, git: false }})} className="tg" >
            </a>
        </div>
    )
}
