import React, { useState } from "react";
import './null.scss';
import './App.scss';
import { Logo } from './components/Logo';
import { Soc } from './components/Soc';
import { Context } from "./index";
import { Marquee } from "./components/Marquee";
import { Preloader } from './components/Preloader';

function App() {
  const [context, setContext] = useState({
    isLoad: false,
    soc: {
      vk: false,
      tg: false,
      git: false
    },
    logo: [3, 2, 1, 5, 6, 1, 3 , 2, 3, 4],
    logo_focus: true 
  });
  function paragraphs(context){
    let arr = [];
    if(context.vk){
      for (let i = 0; i < 10; i++) {
        arr.push(<p key={i} id="vk_marquee">vk</p>);
      }
    }
    else if(context.tg){
      for (let i = 0; i < 4; i++) {
        arr.push(<p key={i} >telegram</p>);
      }
    }
    else if(context.git){
      for (let i = 0; i < 4; i++) {
        arr.push(<p key={i} id="git_marquee" >github</p>);
      }
    }
    return arr;
  }
  function preloader(){
    window.onload = () =>{
      setContext({...context, isLoad: true});
    };
    return context.isLoad;
  }
  return (
    <Context.Provider value={[context, setContext]}>
      {!preloader()?<Preloader />:<div></div>}
      <div className="backgroundApp">
        <Marquee text={paragraphs(context.soc)} />
        <div className="App">
          <Logo />
          <Soc />
        </div>
      </div>
      </Context.Provider>
  );
}

export default App;
