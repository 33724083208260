import React, { useEffect, useContext } from 'react';
import { Context } from "../index";
import { GenNums } from '../engine';
import { logo_name, count } from '../constants';


export const Logo = () => {
    const [context, setContext] = useContext(Context);
    let genNums = new GenNums();
    function gen(count){
            setContext({...context, logo: genNums.genereateNums(count)})
    }
    function showHelper(){
        let result = '';
        !context.logo_focus?
            result = (<div className="Helper"><p>Reload</p></div>)
        :
        result = (<div></div>)
        return result
    }
    useEffect(() => {
        gen(count)
        // eslint-disable-next-line
      }, []);
    return (
        <div className="Logo__Container">
            {showHelper()}
            <div onClick={()=>{gen(count)}} onMouseOver={()=>{setContext({...context, logo_focus: false})}} onMouseOut={()=>{setContext({...context, logo_focus: true})}} className="Logo">
                {logo_name.map((letter, i) => {
                    return (<img className={letter} key={i} src={"./img/"+letter+"/"+letter+context.logo[i]+".webp"} alt={letter} />);
                    })
                }
            </div>
        </div>
    )
}
