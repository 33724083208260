import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './main.css';

export const Context = React.createContext();

ReactDOM.render(
    <App />,
  document.getElementById('main')
);