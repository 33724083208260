import React from 'react'

export const Marquee = (props) => {
    return (
        <div className="Marquee">
            <div className="Marquee_top">{props.text}</div>
            <div className="Marquee_bottom">{props.text}</div>
        </div>
    )
}
